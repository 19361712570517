

.allInfo {
    text-align: left;
    margin-top: 50px;
}

.info {
    font-size: 15px;
    margin-left: 25px;
    margin-bottom: 5px;
}

.category {
    font-family: Jost-SemiBold, Arial, sans-serif;
    font-size: 17px;
    margin-left: 15px;
    margin-bottom: 3px;
}

.backArrow {
    width: 12px;
    margin-right: auto;
    position: absolute;
    left: 20px;
    top: 15px;
    fill: var(--color-text);
}