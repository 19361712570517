
.slideMenu {
    position: fixed;
    background-color: var(--color-background);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    pointer-events: all;
}


#slideMenuBackground {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}

.slideMenuModalOverlay {
    background-color: var(--color-background-modal-overlay);
    transition: background-color 200ms ease-in-out;
}

.wrapper {
    margin-top: 10px;
    display: flex;
}

