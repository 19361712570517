
.backArrow {
    width: 12px;
    margin-right: auto;
    position: absolute;
    left: 20px;
    top: 20px;
}

#playerSettings {
    margin-left: 5px;
    margin-top: 50px;
}


.settingLabel {
    font-family: Jost-SemiBold, Arial, sans-serif;
    font-size: 17px;
    text-align: left;
    margin-left: 20px;
    margin-top: 5px;
    display: block;
}

.settingMessage {
    text-align: left;
    line-height: 20px;
    margin-left: 20px;
    margin-top: 7px;
    max-width: 280px;
    color: var(--color-text-light);
}

#toggleContainer {
    width: 50px;
    margin-left: auto;
    margin-right: 15px;
}

#settingsToggle {
    margin-top: 6px;
}

.option{
    margin-bottom: 20px;
}


#modulationValue {
    margin-left: auto;
    margin-right: 20px;
    margin-top: 20px;
    color: var(--color-text-light);
}

#modulationContainer {
    display: flex;
    margin-left: 30px;
    margin-top: 7px;
}

.control {
    background-color: var(--color-text-light);
    width: 60px;
    height: 46px;
    position: relative;
}

.control:active {
    background-color: var(--color-text-light);
}

#left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

#key {
    border-top: 3px solid #b0b0b0;
    border-bottom: 3px solid #b0b0b0;
    width: 80px;
}

#right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.addSubtractIcon {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 50px;
    height: 50px;
    transform: translate(50%, -50%);
    filter: brightness(0) invert(1);

}

#resetButton {
    font-family: Jost-SemiBold, Arial, sans-serif;
    background-color: var(--color-text-light);
    color: white;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-decoration: none;
    border: none;
    width: 70px;
    height: 46px;
    margin-left: 40px;
    border-radius: 10px;
}
