button.appleSignInBase {
    background-color: black;
    color: white;
    font-family: -apple-system, sans-serif;
    border-style: none;
    box-shadow: 1px 1px 3px #888888;
    cursor: pointer;
}

button.appleSignInSettings {
    height: 40px;
    width: 250px;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 600;
}

button.appleSignInWelcome {
    height: 40px;
    width: 300px;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    font-weight: 600;
}

button.appleSignInSmall {
    width: 45px;
    height: 45px;
    border-radius: 8px;
    position: absolute;
    left: 130px;
    top: 180px;
}
