#heading {
    width: 200px;
    font-size: 20px;
    font-weight: 600;
    justify-content: left;
    text-align: left;
    padding-top: 12px;
    padding-left: 20px;
    padding-bottom: 10px;
}

#reportContent {
    width: 100%;
    height: 220px;
}

#reportLabels {
    width: 100%;
    height: 50px;
    display: flex;
}

#problemTag {
    color: var(--color-text);
    position: absolute;
    top: 50%;
    left: 72%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100px;
    height: 30px;
    outline: none;
    line-height: 30px;
    background-color: var(--color-background);
    border: none;
    border-radius: 4px;
}

#problemTag:hover {
    cursor: pointer;
}

#voice {
    color: var(--color-text);
    position: absolute;
    top: 50%;
    left: 60%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 75px;
    height: 30px;
    outline: none;
    background-color: var(--color-background-search);
    border: none;
    border-radius: 4px;
}

#voice:hover {
    cursor: pointer;
}

#measureContainer {
    display: flex;
    height: 30px;
    width: 100px;
    margin-top: 5px;
}

#problemMeasure {
    width: 25px;
    height: 20px;
    border: none;
    border-radius: 3px;
    margin-left: 4px;
    margin-top: 4px;
    color: var(--color-text);
    background-color: var(--color-background-search);
}

#descriptionLabel {
    color: var(--color-text);
    font-size: 16px;
    font-weight: 570;
    text-align: left;
    margin-left: 10%;
}

#description {
    width: 100%;
    height: 120px;
}

#descriptionBox {
    color: var(--color-text);
    background-color: var(--color-background-search);
    font-size: 17px;
    font-family: inherit;
    width: 80%;
    height: 80px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    border-radius: 4px;
}


#submitButton {
    font-family: Jost-SemiBold, sans-serif;
    position: absolute;
    color: var(--color-text-button);
    right: 50px;
    height: 35px;
    width: 75px;
    outline: none;
    border: none;
    border-radius: 3px;
    background-color: var(--color-background-button);
    font-weight: 550;
}

#submitButton:hover {
    cursor: pointer;
}


#modal-backdrop {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: -1;
}

#modal {
    width: 220px;
    height: 180px;
    background-color: var(--color-background);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0;
    margin: auto;
    border-radius: 12px;
    display: block;
    pointer-events: none;
}

#modal.active {
    pointer-events: all;
    z-index: 100;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 300ms ease-in-out;
}

#modal-backdrop.active {
    z-index: 99;
    background-color: var(--color-background-modal-overlay);
    transition: background-color 300ms ease-in-out;
}

#closeIcon {
    width: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    fill: var(--color-text);
}

.modal-message {
    position: absolute;
    font-size: 18px;
    top: 20px;
    margin: 10px 30px 0 30px;
}

