.content {
    position: relative;
    text-align: left;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    background-color: var(--color-background-template);
}

.contentBlock {
    width: 85%;
    padding: 15px;
    background-color: var(--color-background);
    border-radius: 17px;
    margin: 20px auto 20px auto;
}

.centeredContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h2 {
    margin-top: 0;
}

ul {

}