#chooseChurchSkip {
  display: flex;
  align-items: center;
  font-family: Jost-SemiBold, Arial, sans-serif;
  height: 55px;
  cursor: pointer;
  filter: invert(11%) sepia(1%) saturate(0%) hue-rotate(176deg) brightness(95%) contrast(91%);
  top: 10px;
  right: 10px;
}

#optionsIcon {
  width: 35px;
  height: 35px;
  padding-right: 15px;
  filter: invert(0%) sepia(79%) saturate(564%) hue-rotate(61deg) brightness(101%) contrast(82%);
}

#reportIssueIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  transform: scale(.75);
  filter: invert(85%) sepia(0%) saturate(1247%) hue-rotate(168deg) brightness(86%) contrast(81%);
}

.kebabIcon {
  width: 40px;
  height: 40px;
  padding-right: 12px;
  padding-top: 4px;
  cursor: pointer;
  fill: var(--color-text);
}

.settingsHeaderIcon {
  width: 50px;
  height: 50px;
  padding-right: 12px;
  cursor: pointer;
  fill: var(--color-text);
}

.searchHeaderIcon {
  width: 40px;
  height: 40px;
  padding-right: 12px;
  cursor: pointer;
  fill: var(--color-text);
}

.closePush {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
}
