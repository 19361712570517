

.settingsContent {
    background-color: var(--color-background-template);
    height: 100%;
    overflow: auto;
}

.settingBoxHeader {
    text-align: left;
    font-size: .75rem;
    color: var(--color-text-light);
    text-indent: 3px;
    padding: 2px;
}

.settings {
    background-color: transparent;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin-bottom: 35px;
    text-wrap: nowrap;
}

.setting {
    background-color: var(--color-background);
    border-bottom: 1px solid var(--color-background-template);
    height: 40px;
}

.settingName {
    padding-left: 10px;
    text-align: left;
    width: 130px;
}

.householdValue {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-overflow: ellipsis;
    height: 40px;
}

.currentValue {
    color: var(--color-text-light);
    text-align: right;
    overflow: hidden;
}

.currentValueEllipse {
    color: var(--color-text-light);
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0;
}

.settingAction {
    width: 20px;
}

.editable {
    padding: 0 20px 0 10px;
    width: 7px;
}


.toggle {
    width: 40px;
    margin-left: auto;
    margin-right: 20px;
}

.hidden {
    display: none;
}
