#playerPage {
    display: flex;
    position: fixed;
    top: 55px;
    bottom: -70px;
    transition: left 200ms;
}

.playerPageContent {
    height: 100%;
    width: 100vw;
}

.upperDiv {

}

/* range styling with larger thumb */
input[type=range] {
    -webkit-appearance: none;
    margin: 3.3vh 10px;
    width: 90%
}
input[type=range]:focus {
    outline: none;
}
.letter_s::-webkit-slider-thumb {
    /*box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;*/
    border: 2px solid var(--color-slider-thumb-border);
    height: 48px;
    width: 48px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    background-size:15px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/letter_s.svg);
    background-color: var(--color-slider-thumb);
}
.letter_a::-webkit-slider-thumb {
    /*box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;*/
    border: 2px solid var(--color-slider-thumb-border);
    height: 48px;
    width: 48px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    background-size:15px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/letter_a.svg);
    background-color: var(--color-slider-thumb);
}
.letter_t::-webkit-slider-thumb {
    /*box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;*/
    border: 2px solid var(--color-slider-thumb-border);
    height: 48px;
    width: 48px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    background-size:15px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/letter_t.svg);
    background-color: var(--color-slider-thumb);
}
.letter_b::-webkit-slider-thumb {
    /*box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;*/
    border: 2px solid var(--color-slider-thumb-border);
    height: 48px;
    width: 48px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    background-size:15px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/letter_b.svg);
    background-color: var(--color-slider-thumb);
}
.letter_p::-webkit-slider-thumb {
    /*box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;*/
    border: 2px solid var(--color-slider-thumb-border);
    height: 48px;
    width: 48px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    background-size:23px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/eighth-note.svg);
    background-color: var(--color-slider-thumb);
}
.tempo::-webkit-slider-thumb {
    /*box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;*/
    border: 2px solid var(--color-slider-thumb-border);
    height: 48px;
    width: 48px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    background-size:25px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../assets/stopwatch.png);
    background-color: var(--color-slider-thumb);
}
.locationSliderThumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-shadow: none;
    border: 4px solid var(--color-slider-thumb-border);
    height: 25px;
    width: 25px;
    margin-top: -8.5px;
    margin-left: -0.5px;
    border-radius: 50%;
    background: var(--color-background);
    position: relative;
}

.locationSliderThumbHidden::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: transparent;
    border: none;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    background: black;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}
input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0 0 1px #0d0d0d;
    border: 1px solid #000000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

#slider-form {
    margin-top: 3%;
}

.sliderTable {
    margin: auto;
    width: 90vw;
}

.tempoTimeSliderTable {
    margin: auto;
    width: 90vw;
}

.sharedSliderTd {
    text-align: left;
    /*
     * causes Android browser to fire onPointerUp event instead of eating it
     * https://github.com/facebook/react/issues/13333
     */
    touch-action: none;
}

.selectedVoice {
  box-shadow: 0 0 2px 0 #b0b0b0;
}

.pianoVoiceSpacer {
  padding-top: 18px;
}

.fadedVoice {
    opacity: 50%;
}

#bass-label,
#tenor-label,
#alto-label,
#soprano-label {
    font-family: Jost-SemiBold, Arial, sans-serif;
    font-size: larger;
}

.sliderLabelColumn {
    width: 80px;
}

.locationValue {
    width: 80px;
    text-align: right;
}

body {
    text-align: center;
}

.bottomDiv {
    display: block;
    width: 100%;

}

#playerIcons {
    width: 100%;
}

#upperPlayerIcons {
    width: 100%;
    display: flex;
    height: 120px;
}

#lowerPlayerIcons {
    margin-top: 20px;
    width: 100%;
}

.alternateDisplayIcons {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 80px;
}

#heartContainer {
    margin-left: 20px;
    position: relative;
    width: 33px;
}

#playbackIconsContainer {
    display: flex;
    margin: auto;
}


.playPauseButton {
    border: none;
    background: transparent;
    padding: 0 0 0 0;
    cursor: pointer;
}

.playPauseIcon {
    width: 80px;
    fill: var(--color-text);
}

.skipIconRight {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 60px;
    background-color: transparent;
    border: none;
}

.skipIconLeft {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 60px;
    background-color: transparent;
    border: none;
}

#repeatContainer {
    margin-right: 20px;
    position: relative;
    width: 40px;
}

.repeatButton {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 40px;
    cursor: pointer;
}


.playPauseButton:active {
    transform: scale(110%);
    background: transparent;
}

.greyedOutSvg {
    /* TODO(hewitt): what is this color for? */
    filter: invert(85%) sepia(0%) saturate(1247%) hue-rotate(168deg) brightness(86%) contrast(81%);
}

.heartIcon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 33px;
    cursor: pointer;
    fill: var(--color-text);
}

.loading {
    font-family: Jost-SemiBold, Arial, sans-serif;
    width: 100px;
    line-height: 100px;
    text-align: center;
    font-size: x-large;
}

#verseContainer {
    margin-top: 0;
    height: 40px;
    border-radius: 5px;
    display: flex;
}

.verse {
    font-size: 17px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    margin: 2px;
    cursor: pointer;
    font-weight: 500;
}

#stickyFooter {
    height: 20px;
    width: 100vw;
    position: fixed;
}

#dotsContainer {
    width: 50px;
    height: 20px;
    margin: auto;
    justify-content: center;
}

.dot {
    margin-top: 6px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    cursor: pointer;
}

#desktopPdfPrompt {
    position: fixed;
    right: 20px;
    bottom: 15px;
}

#desktopBackPrompt {
    position: fixed;
    left: 20px;
    bottom: 15px;
}

.promptText {
    font-weight: 600;
    line-height: 50px;
    color: #464646
}

.arrowPointer {
    height: 50px;
    cursor: pointer;
    /* TODO(hewitt): what is this color for? */
    filter: invert(22%) sepia(41%) saturate(0%) hue-rotate(240deg) brightness(109%) contrast(93%);
}

@media screen and (orientation: landscape) {

    input[type=range] {
        margin: 2.4vh 10px;
    }
}


/*The skip icons are shrinking for some reason on ipad and iphone*/
/*this fixes it on iphone i guess; need better solution*/

/*@media screen and (max-width: 450px) {*/
/*    .skipIconRight {*/
/*        transform: translateX(50%) translateY(20px) scale(1.5);*/
/*    }*/
/*    .skipIconLeft {*/
/*        transform: translateX(50%) translateY(20px) scale(1.5);*/
/*    }*/
/*}*/


@media screen and (orientation: landscape) and (max-height: 550px) {
    .icons {
        margin-top: -20px;
    }

    .playerPageContent {
        display: flex;
    }

    input[type=range] {
        margin: 6vh 10px;
    }

    .sharedSliderTd {
        width: 90%;
    }

    .bottomDiv {
        margin-top: 8px;
        width: 100%;
    }

    .alternateDisplayIcons {
        margin-top: 70px;
    }

    #slider-form {
        margin-top: 2%;
        width: 100%
    }
    #second-slider-form {
        width: 100%
    }
    .sliderTable {
        width: 100%;
    }
    .tempoTimeSliderTable{
        width: 100%
    }
    .lowerDiv {
        width: 50%;
    }
    .upperDiv {
        width: 50%
    }
    .locationValue {
        width: 15%
    }
}

.vocalsIcon {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--color-text);
}
