.navOption{
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    width: 100%;
}

.optionText {
    font-size: 18px;
    font-weight: 640;
    line-height: 30px;
}

.icon {
    height: 30px;
    width: 30px;
    color: var(--text-color);
}
