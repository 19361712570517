

#verseContainer {
    margin-top: 0;
    height: 40px;
    border-radius: 5px;
    display: flex;
}

.verse {
    font-size: 17px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    margin: 2px;
    cursor: pointer;
    font-weight: 500;
}