

.verseCountSettings {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.verseCountSetting {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.verses {
    text-align: left;
    padding-left: 20px;
    color: var(--color-text);
}

.explanation {
    min-width: 300px;
    color: var(--color-text-light);
    font-size: 14px;
    padding-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    white-space: normal;
    text-align: left;
}

.settingsBox {
    position: relative;
    top: 30px;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 35px;
}
