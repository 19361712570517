.App {
  /*text-align: center;*/
}

* {
  /* disables selection of elements by touching them */
  -webkit-user-select: none;
}

button {
  font-family: inherit;
}

input {
  font-family: inherit;
}

select {
  font-family: inherit;
}

#hymnal-header, #songs-header{
  text-align: center;
}

#hymn-list, #hymnal-list {
  text-align: left;
  text-underline: none;
}

#this-week-troubleshooting-link {
  text-decoration: #723ee7 !important;
}

ul {
  list-style-type: none;
  padding-top: 0;
  padding-left: 5%;
}

a {
  color: inherit;
}

#homepage-list li {
  padding: 4px;
  white-space: nowrap;
}

#hymn-list li, #hymnal-list li, #churches-list li, #menu-list li {
  padding: 4px;
  white-space: nowrap;
  text-align: left;
}

#this-week-error-troubleshooting, #hymnal-error-troubleshooting {
  text-align: left;
  margin: 10px;
}

#this-week-error-troubleshooting ul li, #hymnal-error-troubleshooting ul li {
  margin: 3px;
}

#this-week-error-troubleshooting ul, #hymnal-error-troubleshooting ul {
  list-style-type: '- ' !important;
}

#church-selector-label {
  margin: 5px;
}

.helpPageContent {
  height: 100vh;
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
}

.searchFullWidth {
  width: 100%;
}

.searchPartialWidth {
  width: 85%;
}

.searchInput {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: medium;
  box-sizing: border-box;
}

.searchInput:focus {
  outline: 0 none;
  color: var(--color-text);
}

.searchInput::placeholder{
  color: var(--color-text-search-placeholder);
}

.cancelSearchIcon {
  float: right;
  width: 25px;
  margin-right: 8px;
  fill: var(--color-text-search-placeholder);
}

.playAllButtonContainer {
    width: 10%;
}


input[type="checkbox"] {
  text-align: right;

  border-radius: 20px;
  height: 30px;
  width: 50px;
  -webkit-appearance: none;
  outline: none;
  background-color: var(--color-checkbox-unselected);
}

input:checked[type="checkbox"] {
  background-color: var(--color-checkbox-selected);
}

input[type="checkbox"]::before {
  transition: .35s;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #fff;
  position: absolute;
  margin-top: 3px;
  transform: translateX(-46px) scale(1.1);
  box-shadow: 0 3px 6px rgba(0,0,0,.2);
}

input:checked[type="checkbox"]::before {
  transform: translateX(-28px) scale(1.1);
}

.inlineLink {
  background-color: var(--color-background);
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
