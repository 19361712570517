

#accountContainer {
    margin: 90px auto;
    background-color: var(--color-background);
    width: 300px;
    border-radius: 10px;
    padding: 20px 0;
}

