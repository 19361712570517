.footer {
    display: flex;
    flex-basis: auto;
    width: 100vw;
    border-top: 1px solid var(--color-text-light);
    background-color: var(--color-background);
}

.selectedIcon {
    color: var(--color-text);
    fill: var(--color-text);
}

.unselectedIcon {
    color: var(--color-text-light);
    fill: var(--color-text-light);
    cursor: pointer;
}

.selectedLibraryIcon {
    /* color & fill don't work for the library "svg" since it is an image in disquise - eventually replace */
    filter: invert(0%) sepia(0%) saturate(1647%) hue-rotate(200deg) brightness(91%) contrast(92%);
    @media (prefers-color-scheme: dark) {
        filter: invert(100%) sepia(0%) saturate(1647%) hue-rotate(200deg) brightness(91%) contrast(92%);
    }
}

.unselectedLibraryIcon {
    /* color & fill don't work for the library "svg" since it is an image in disquise - eventually replace */
    filter: invert(73%) sepia(7%) saturate(19%) hue-rotate(317deg) brightness(91%) contrast(83%);
}

.iconLabel {
    font-family: Jost-SemiBold, Arial, sans-serif;
    font-size: 12px;
    margin-top: -2px;
}

.selectedLabel {
    color: var(--color-text);
}

.unselectedLabel {
    color: #a4a4a4;
    cursor: pointer;
}

.thisWeekIcon {
    margin-top: 1px;
    margin-bottom: -4px;
    width: 37px;
    height: 37px;
}

.libraryIcon {
    margin-top: 4px;
    margin-bottom: -7px;
    width: 37px;
    height: 37px;
}

.favoritesIcon {
    margin-top: 2px;
    margin-bottom: -6.5px;
    width: 39px;
    height: 39px;
}

.settingsIcon {
    margin-top: 3px;
    margin-bottom: -4px;
    width: 36px;
    height: 36px;
}

.helpIcon {
    margin-top: 4px;
    margin-bottom: -3px;
    width: 35px;
    height: 35px;
}

.footerIcon {
    padding-bottom: 5px;
}

.controlsIcon {
    width: 52px;
    height: 52px;
    padding: 0 35px 0 35px;
}

.pdfIcon {
    width: 47px;
    height: 47px;
    padding: 1px 35px 0 35px;
}

.infoIcon {
    width: 50px;
    height: 50px;
    padding: 1px 35px 0 35px;
}

.iconDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 3px;
    height: 100%;
    width: 25%;
    text-decoration: none;
}

.organizationIcon {
    margin-top: 1px;
    margin-bottom: -4px;
    width: 37px;
    height: 37px;
}

.churchIcon {
    margin-top: -1px;
    margin-bottom: -5px;
    width: 41px;
    height: 41px;
}
