#loginDiv {
}

#loginInfo {
}

#profilePicture {
    height: 80px;
    width: 80px;
    border-radius: 40px;
}

#lowerDiv {
    padding-top: 40px;
}

#signOutButton {
    background-color: #d6d6d6;
    border: none;
    height: 40px;
    width: 90px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 50px;
    box-shadow: 1px 1px 3px #888888;
}
