.hymnalList {
  width: 100%;
}

.hymnal {
  font-family: Jost-Regular, Arial, sans-serif;
  font-size: large;
  cursor: pointer;
}

.hymnalIcon {
  vertical-align: middle;
  border-radius: 0.5em;
  border: 1px solid transparent;
}

.hymnalIconBorder {
  border: 1px solid;
}

.hymnalDescription {
  padding: 0.3em;
  vertical-align: middle;
  text-align: left;
}

.hymnalPublisher {
  padding-top: 0.15em;
  color: var(--color-text-light);
  font-family: Jost-Regular, Arial, sans-serif;
  font-size: medium;
}

.hymnalDivider {
  border-width: 0.8px;
  border-color: var(--color-text-light);
  opacity: 50%;
  margin: 0;
}

/*
 * Position based on this stack overflow answer:
 * https://stackoverflow.com/a/46318225/998490
 * Still not quite perfect, but better
 */

.message {
  white-space: normal;
  margin: 40px;
}

caption {
  text-indent: 5px;
}

.songLink {
  cursor: pointer;
}

.vocalsIcon {
  width: 1rem;
  height: 1rem;
  cursor: inherit;
}
